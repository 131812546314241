<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
<style>

</style>
<style scoped>
 .footer-logo{
   max-height: 30px!important;
 }
  #kt_footer{
   background: #6333CE!important; ;
 }
</style>